import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../components/de/layout"
//import Image from "../components/image"
import SEO from "../../components/de/seo"

import MainImg from "./../../images/main_bg.jpg"

import shape1 from "./../../images/dec/1.png"
import shape2 from "./../../images/dec/2.png"
import shape3 from "./../../images/dec/3.png"
import shape4 from "./../../images/dec/4.png"
import shape5 from "./../../images/dec/5.png"
import shape6 from "./../../images/dec/6.png"
import shape7 from "./../../images/dec/7.png"
import shape8 from "./../../images/dec/8.png"

import product2 from "./../../images/moonamar-schwarzkummelol-in-halal-kapseln-250-stuck.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="MOONAMAR - SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
      description="MOONAMAR Schwarzkümmelöl in Kapseln – kaltgepresstes, ägyptisches Schwarzkümmelöl in halal-zertifizierten Gelatine-Kapseln."
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product2}
                alt="SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
                title="SCHWARZKÜMMELÖL IN HALAL-KAPSELN - 250 Stück"
              />
              <div className="_info">
                <div className="_inner">
                  <span>MOONAMAR</span>
                  <div className="title">Schwarzkümmelöl in Kapseln</div>
                  <div className="subtitle">Verpackungsgröße: 250 Stück</div>
                  <div className="price">21,90 €</div>
                </div>
                <div className="_buttonShop">
                <a href="https://www.mykotheke.at/produkte/komplementaere-produkte/bio-schwarzkuemmeloel-vegan-kapseln-250-st-moonamar" target="_blank">ZUR BESTELLUNG</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Produktbeschreibung</Tab>
                <Tab>Zutaten und Verwendung</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    Die Schwarzkümmelöl-Kapseln sind ideale Alternative für
                    Menschen, die den Geschmack des Schwarzkümmelöls nicht
                    vertragen. Sie Eignen sich sowohl für Kinder als auch für
                    erwachsene Menschen.
                  </p>
                  <p>
                    <strong>ÖLHERSTELLUNG</strong>: Kaltpressung ohne weitere
                    Verarbeitung und ohne zusätzliche Zutaten - 100%
                    Schwarzkümmelöl <br /> <strong>ÖLQUALITÄT</strong>: Extra
                    Vergine <br /> <strong>KAPSELHÜLLE</strong>: Besteht aus
                    halal-zertifizierten Gelatine
                    <br /> <strong>SAMENTYP</strong>: Nigella Sativa <br />{" "}
                    <strong>HERKUNFT DER SAMEN</strong>: Ägypten <br />{" "}
                    <strong>STABILITÄT</strong>: Schwarzkümmelöl ist aufgrund
                    seiner Zusammensetzung sehr stabil und beständig gegen
                    Keimvermehrung. Kapselverpackung verleiht dem
                    Schwarzkümmelöl zusätzliche Stabilität gegen Oxidation und
                    Kontamination.
                    <br />
                    <strong>LAGERUNG</strong>: Empfohlen kühl und trocken zu
                    lagern.
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td width="125">
                          <p>
                            <strong>Nährwerte</strong>
                          </p>
                        </td>
                        <td width="67">
                          <p>
                            <strong>pro 100 g</strong>
                          </p>
                        </td>
                        <td width="81">
                          <p>
                            <strong>pro 5 g</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Brennwert</p>
                        </td>
                        <td>
                          <p>
                            3.039 kJ
                            <br /> 735 kcal
                          </p>
                        </td>
                        <td>
                          <p>
                            62,36 kJ
                            <br /> 15,08 kcal
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Fett
                            <br />
                            <em>davon gesättigt</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            67,2 g<br /> 12,2 g
                          </p>
                        </td>
                        <td>
                          <p>
                            1,38 g<br /> 0,25 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Kohlenhydrate
                            <br />
                            <em>davon Zucker</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            14,5 g<br /> &#60; 0,50 g
                          </p>
                        </td>
                        <td>
                          <p>
                            0,3 g<br /> &#60; 0,02 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Eiweiß</p>
                        </td>
                        <td>
                          <p>18,1 g</p>
                        </td>
                        <td>
                          <p>0,37 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Salz</p>
                        </td>
                        <td>
                          <p>0,0045 g</p>
                        </td>
                        <td>
                          <p>&#60; 0,001 g</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <p>
                    Das MOONAMAR-Schwarzkümmelölkapseln bestehen aus reinem
                    ägyptischem, kaltgepresstem Schwarzkümmelöl und einer
                    halal-zertifizierter Gelatine-Hülle.
                  </p>
                  <h3>Zutaten</h3>
                  <p>
                    Schwarzkümmelöl ist reich an verschiedenen Vitaminen,
                    Mineralstoffen, Proteinen, ungesättigten Fettsäuren,
                    Phytosterolen, verschiedenen ätherischen Ölen und Saponinen,
                    sowie Wirkstoffen, die nur in Schwarzkümmelöl vorkommen, wie
                    Nigellon, Thymochinon und Nigellin.
                  </p>
                  <h3>Anwendung:</h3>
                  <p>
                    Schwarzkümmelöl wird traditionell hauptsächlich zur
                    Regulierung des Immunsystems, sowohl als Unterstützung bei
                    diversen Allergiebehandlungen als auch zur Stärkung des
                    Immunsystems, verwendet. Um sich der positiven Effekte zu
                    erfreuen wird das Öl normalerweise mindestens 8 Wochen lang
                    zu sich genommen. Für diejenigen , die sich ungesund
                    ernähren ist Schwarzkümmelöl als Nahrungsergänzung
                    empfehlenswert.
                  </p>
                  <h3>Einnahmeempfehlung</h3>
                  <p>
                    <strong>Erwachsene:</strong> 3 x täglich 2 – 3
                    Schwarzkümmelölkapseln zu den ausgewogenen Mahlzeiten
                    einnehmen. Als Prävention werden 1 x täglich 2-3 Kapseln zum
                    Frühstuck eingenommen.
                    <br /> <strong>Kinder:</strong> 2-3 mal taglich 1 Teelöffel.
                    Als Prävention wird 1 TL täglich vor dem Frühstuck
                    eingenommen. Als Prävention wird 1 TL täglich vor dem
                    Frühstuck eingenommen.
                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default CorokotovoUlje
